<template>
  <div>
    <b-card>
      <p
        @click="generatedCard = !generatedCard"
        block
        v-b-toggle.generatedCard
        variant="light"
        class="col-12"
      >
        <b-row class="justify-content-between">
          <b-col md="auto">
            <b-button :to="{ name: 'create-cancelled-invoice' }">
              <feather-icon icon="FilePlusIcon" size="15" />
              Nouvelle Facture d'avoir
            </b-button>
          </b-col>
          <!-- <b-col md="auto">
                        <b-dropdown :disabled="exportIsProgress" variant="outline-primary" id="dropdown-1" class="ml-2">
                            <template #button-content>
                                <b-spinner v-if="exportIsProgress" small />
                                <span>Exporter</span>
                            </template>
                            <b-dropdown-item>
                                <feather-icon icon="FileTextIcon" size="15" />
                                Exporter en Excel
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <feather-icon icon="FileIcon" size="15" />
                                Exporter en PDF
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col> -->
        </b-row>
      </p>
      <hr />

      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Filtrer par :"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="4"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              ></b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                <option :value="false">Croissant</option>
                <option :value="true">Décroissant</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Recherche..."
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Effacer
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>

      <b-table
        style="height: 47vh"
        empty-text="Aucune facture d'avoir disponible"
        empty-filtered-text="Aucune facture d'avoir trouvée"
        show-empty
        head-variant="dark"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        class="generated-card"
        sticky-header="5"
        striped
        hover
        :items="invoicesDeleted"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(customer)="data">
          {{ data.item.customer.name }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>

            <b-dropdown-item :href="data.item.filename_invoice" target="_blank">
              <feather-icon
                icon="EyeIcon"
                size="18"
                style="margin-right: 5px"
              />
              Voir la facture
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="deletedConfirm(data.item)">
                            <feather-icon icon="TrashIcon" size="18" style="margin-right: 5px;" color="danger" />
                            <span class="text-danger">Supprimer définitivement</span>
                        </b-dropdown-item> -->
          </b-dropdown>
        </template>

        <template #cell(date)="data">
          {{ data.item.created_at }}
        </template>
      </b-table>

      <b-modal
        centered
        size="sm"
        hide-footer
        no-close-on-backdrop
        v-model="showModal"
        id="modal-center"
        :title="'Suppression de Facture N° : ' + thisInvoice.num"
      >
        <hr />
        <p>
          Voulez-vous vraiment supprimer cette facture ?
          <br />
          Cette action est irréversible
        </p>
        <hr />
        <div class="text-center">
          <b-button
            size="sm"
            class="mr-1"
            variant="outline-primary"
            @click="showModal = false"
          >
            Non, annuler
          </b-button>
          <b-button
            :disabled="loading"
            size="sm"
            @click="deleteThisInvoice(thisInvoice)"
          >
            <b-spinner v-if="loading" small />
            <feather-icon v-else icon="SendIcon" />
            Oui, supprimer
          </b-button>
        </div>
      </b-modal>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Affichage"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BToast } from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BToast,
    ToastificationContent,
    FeatherIcon,
  },
  setup() {
    return {
      showModal: false,
      loading: false,
      thisInvoice: {},
      invoice: {
        num: "",
      },

      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "date",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      instanceCard: false,
      generatedCard: true,
      fields: [
        // { key: "id", label: "#", sortable: true },
        { key: "num", label: "Numéro", sortable: true },
        { key: "customer", label: "Client", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "actions", thStyle: { width: "5%" } },
      ],
      exportIsProgress: false,
    };
  },
  computed: {
    ...mapState("tasks", ["invoicesDeleted"]),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.allInvoicesCanceled();
    this.totalRows = this.invoicesDeleted.length;
  },

  methods: {
    ...mapActions("tasks", ["allInvoicesCanceled", "forceDeleteInvoice"]),

    goBack() {
      history.back();
    },

    deletedConfirm(data) {
      this.showModal = true;
      this.thisInvoice = data;
    },

    deleteThisInvoice(data) {
      this.loading = true;
      this.forceDeleteInvoice(data.id).then((e) => {
        this.allInvoicesCanceled();
        this.loading = false;
        this.showModal = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Facture supprimée avec succès",
            icon: "BellIcon",
            variant: "success",
          },
        });
      });
    },

    getDate(date) {
      return moment(date).locale("fr").format("DD MMMM YYYY");
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.generated-card {
  max-height: 70vh;
}
</style>
